import React, {useState, useEffect, useCallback} from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import queryString from 'query-string'
import {graphql, useStaticQuery  } from "gatsby";
import {parse as parsePostCode} from 'postcode'
import postcodeLookup from '../postcode-lookup'

import Header from "../components/Header";
import Footer from "../components/Footer";
import CqcApproved from '../components/CqcApproved'
import { InlineWidget, CalendlyEventListener } from "react-calendly";

import "../../scss/main.scss";
import PhoneIconCard from "../components/icons/Footer/PhoneIconCard";
import Trustpilot from "../components/Trustpilot/Trustpilot";
import {LinkWithUTM as Link} from '../components/LinkWithUTM'
import GreenCheckMark from "../components/icons/GreenCheckMark";
import CallIcon from '../components/icons/CallIcon'
/* global _paq */

const isBrowser = typeof window !== "undefined"

const ActionPanel = ({gtmContainerId}) => {
    const [formState, setFormState] = useState({
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        number: undefined,
        type: 'client'
    })
    const [isSubmitComplete, setIsSubmitComplete] = useState(false)
    const [matches, setMatches] = useState();

    useEffect(() => {
        if (!gtmContainerId) return
        window.dataLayer = window.dataLayer || [];

        function gtag(){window.dataLayer.push(arguments)};
        gtag('js', new Date());
        gtag('config',gtmContainerId);
    }, [gtmContainerId])

    const trackCalendlyEvent = useCallback((interaction_type) => {
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            event: 'calendly_event',
            interaction_type
        });
    }, [])

    const trackFormSubmissionEvent = useCallback(() => {
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            event: 'edyn_form_submission',
            form_name: 'book_consultation',
            lead_type: formState.type
        });
    }, [])



  
    useEffect(() => {
      if (!isBrowser) return;

      const mediaMatch = window.matchMedia('(min-width: 800px)');
      setMatches(mediaMatch.matches)
      const handler = e => setMatches(e.matches);
      mediaMatch.addEventListener('change', handler);
      return () => mediaMatch.removeEventListener('change', handler);
    }, []);

    const prefill = {
        name: `${formState.firstName} ${formState.lastName}`,
        email: formState.email,
        phone: formState.number
    }

    const contentSection = (isSubmitComplete && formState.type === 'carer') ? (
        <p className="secondary" style={{padding: '2rem'}}>Thank you for submitting your details</p>
    ) : isSubmitComplete ? (
        <div style={{width: '100%', height: 'auto'}}>
            <CalendlyEventListener
                onEventTypeViewed={() => {
                    trackCalendlyEvent('event_type_viewed')
                }} onEventScheduled={() => {
                    trackCalendlyEvent('event_scheduled')
                }} onDateAndTimeSelected={() => {
                    trackCalendlyEvent('date_and_time_selected')
                }} onProfilePageViewed={() => {
                    trackCalendlyEvent('profile_page_viewed')
                }}
            >
            </CalendlyEventListener>
            <InlineWidget
                prefill={prefill}
                url='https://calendly.com/d/cqdy-tpd-9kv/looking-for-care-consultation'
                styles={{
                    height: matches ? '800px': '1120px'
                }}
            />
        </div>

    ) : (
        <div className="appointment-form-frame">
            <form className="appointment-form" action="" method="GET">
                <h2>Enter your details to book an appointment</h2>
                <h2 className="mobile-heading">Next steps:</h2>


                <div className="input-block">
                    <label>First name</label>
                    <input
                        className="default"
                        type="text"
                        id="fname"
                        name="firstname"
                        placeholder="Your first name"
                        value={formState.firstName}
                        onChange={e => {
                            setFormState({...formState, firstName: e.target.value})
                        }}
                    />
                </div>

                <div className="input-block">
                    <label>Email address</label>
                    <input
                        className="default"
                        type="email"
                        id="email"
                        name="emailaddress"
                        placeholder="Your email"
                        value={formState.email}
                        onChange={e => setFormState({...formState, email: e.target.value})}
                    />
                </div>

                <div className="input-block">
                    <label>Surname</label>
                    <input
                        className="default"
                        type="text"
                        id="surname"
                        name="surname"
                        placeholder="Your surname"
                        value={formState.lastName}
                        onChange={e => setFormState({...formState, lastName: e.target.value})}
                    />
                </div>

                <div className="input-block">
                    <label>Phone number</label>
                    <input
                        className="default"
                        type="number"
                        id="number"
                        name="phonenumber"
                        placeholder="Your phone number"
                        value={formState.number}
                        onChange={e => setFormState({...formState, number: e.target.value})}
                    />
                </div>

                <div className="submit-block">
                    <input
                        className="submit-button"
                        type="submit"
                        value={'Select an appointment'}
                        onClick={async (e) => {
                            e.preventDefault()
                            trackFormSubmissionEvent();
                            let meta;
                            try {
                                meta = queryString.parse(document.location.search)
                            } catch (e) {
                                console.error(e)
                            }

                            await fetch('https://hooks.zapier.com/hooks/catch/6117983/bovj28r/', {
                                method: 'POST',
                                mode: 'cors',
                                cache: 'no-cache',
                                credentials: 'same-origin',
                                headers: {}, // zapier does not return the correct cors headers, when means we cannot set a 'Content-Type'
                                redirect: 'follow',
                                referrerPolicy: 'no-referrer',
                                body: JSON.stringify({
                                    firstName: formState.firstName,
                                    lastName: formState.lastName,
                                    email: formState.email,
                                    phone: formState.number,
                                    enquiryType: formState.type,
                                    formName: 'book consulation',
                                    meta
                                })
                            })
                            setIsSubmitComplete(true)
                        }}
                    />
                </div>
                <p className="secondary">
                    ‘By providing us with your name, phone number and email
                    address, you consent for us to contact you by email or phone to
                    inform you of our services and other information we think
                    will be of interest to you. You can withdraw your permission
                    at any time by contacting us. <span className="underline">Privacy Policy.</span>'
                </p>
            </form>
        </div>
    )

    return (
        <section>
            <div className="appointment-booking">
                <div className="appointment-details">
                    {/* <PhoneIconCard className="icon-default" /> */}
                    <div className="details-number">
                        <CallIcon></CallIcon>
                        <div className={'details-number-header'}>
                            <p className="secondary">
                                If you'd rather, you can just call us now:
                            </p>
                            <a href='tel:02039709900'>
                                <h4 className="number">020 3970 9900</h4>
                            </a>
                        </div>
                    </div>
                </div>
                {contentSection}
            </div>
        </section>
    )
}

export default function CTA() {
    const [districtName, setDistrictName] = useState()

    const { 
        site: {
            siteMetadata: {
                appConfig: {
                    gtmContainerId
                }
            }
        }
	} = useStaticQuery(
		graphql`
		  query {
            site {
                siteMetadata {
                    appConfig {
                        gtmContainerId
                    }
                }
            }
          }
        `)


    useEffect(() => {
        try {
            console.log(queryString.parse(document.location.search))
            console.log(parsePostCode(queryString.parse(document.location.search).postcode))
            const postcode = parsePostCode(queryString.parse(document.location.search).postcode)
            const districtName = postcodeLookup[postcode.district]
            const subDistrictName = postcodeLookup[postcode.subDistrict]
            setDistrictName(districtName || subDistrictName)
        } catch (e) {
            console.error(e);
        }
    }, [])
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | CTA</title>
                <meta property="og:title" content="Edyn Care | CTA"/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png"/>
				<meta property="og:url" content="https://www.edyn.care/cta" />

                <meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content="https://www.edyn.care/cta" />
				<meta name="twitter:title" content="Edyn Care | CTA" />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
			</Helmet>

			<div className="container">
				<Header />
				<main className="cta-main-container">
					<div className="cta-landing">
						<div className="landing-content">
							<StaticImage placeholder="blurred"
								src="../../static/assets/marketing-site/images/cta-image-02.png"
								objectFit='contain'
							/>
                            {districtName ? (
                                <>
        							<h1>We have carers in <br/>{districtName}.</h1>
                                    <h2>Book your phone care consultation</h2>
                                </>
                            ) : (
    							<h1>Book your phone care consultation</h1>
                            )}
							<p className="primary">
								Select a time to chat with one of our family care advisors to
								get started.
							</p>
						</div>
						
					</div>

                    <ActionPanel gtmContainerId={gtmContainerId} />
                    <CqcApproved/>
                    <section className="cta-vijay-quote-section">
                        <svg className="cta-vijay-quote-section__background" width="1023" height="136" viewBox="0 0 1023 136" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M331.061 -670.972C404.355 -720.159 488.129 -761.948 583.568 -762.166C679.007 -762.384 762.562 -720.452 836.279 -671.619C909.995 -622.786 989.685 -566.166 1019.08 -483.215C1048.33 -400.782 1021.17 -312.586 993.353 -233.995C965.535 -155.404 910.561 -95.1465 833.967 -44.0653C757.005 7.53368 676.382 29.5999 585.443 29.8315C494.503 30.0631 414.794 7.44516 337.443 -43.7996C260.638 -94.7241 178.767 -146.146 150.772 -224.648C122.777 -303.15 158.912 -387.797 187.882 -470.196C216.777 -553.147 257.713 -621.785 331.061 -670.972Z" stroke="#7DDE8B"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M315.701 -692.708C393.272 -744.793 481.438 -788.982 582.322 -789.166C683.206 -789.35 776.081 -751.174 853.951 -699.375C931.822 -647.577 1013.01 -582.204 1044.1 -494.208C1075.08 -406.635 1061.39 -307.549 1031.91 -224.064C1002.43 -140.579 927.318 -81.8466 846.188 -27.6049C764.765 27.0531 679.919 48.6188 583.805 48.8303C487.692 49.0419 394.878 38.0065 313.149 -16.3717C231.855 -70.4838 149.922 -134.887 120.302 -218.297C90.6822 -301.707 136.116 -391.887 166.893 -479.446C197.574 -567.483 238.136 -640.622 315.701 -692.708Z" stroke="#7DDE8B"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M308.316 -713.944C390.319 -768.743 474.637 -822.03 581.235 -822.166C687.833 -822.303 782.631 -782.196 864.852 -727.615C947.073 -673.034 1037.24 -608.672 1070.11 -515.939C1102.91 -423.527 1085.29 -319.276 1054.07 -231.216C1022.86 -143.156 947.291 -78.2145 861.42 -21.011C775.324 36.499 683.87 42.6907 582.325 42.827C480.779 42.9632 385.982 41.4237 299.655 -15.8751C213.654 -72.9696 119.415 -135.643 88.093 -223.662C56.7707 -311.681 110.979 -407.574 143.622 -500C176.204 -592.726 226.32 -659.152 308.316 -713.944Z" stroke="#7DDE8B"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M295.653 -725.646C382.086 -783.342 472 -835.098 584.299 -835.167C696.598 -835.235 796.247 -795.753 882.83 -738.234C969.413 -680.715 1078.15 -617.155 1112.8 -519.445C1147.41 -421.932 1104.91 -310.918 1071.97 -218.042C1039.03 -125.167 969.256 -53.9555 878.654 6.37262C787.902 66.9122 692 81.7367 585.049 81.8322C478.097 81.9277 375.771 75.4291 284.869 15.0055C194.185 -45.2613 89.1278 -112.143 56.1227 -205.018C23.1176 -297.894 75.878 -405.287 110.368 -502.806C144.79 -600.448 209.213 -667.977 295.653 -725.646Z" stroke="#7DDE8B"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M268.818 -754.884C359.63 -815.348 462.949 -864.126 580.871 -864.167C698.794 -864.208 802.869 -816.445 893.756 -756.023C984.643 -695.6 1081.82 -624.407 1118.24 -521.798C1154.63 -419.292 1119.77 -310.153 1085.11 -212.522C1050.46 -114.89 976.36 -48.4275 881.073 14.9536C785.71 78.437 693.556 110.792 581.239 110.833C468.923 110.874 359.862 99.9594 264.397 36.5238C169.075 -26.7891 69.2511 -101.896 34.5664 -199.514C-0.118358 -297.132 56.0161 -404.533 92.3629 -507.032C128.682 -609.674 178.005 -694.414 268.818 -754.884Z" stroke="#7DDE8B"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M581.779 -885.167C458.06 -885.167 354.01 -833.609 258.72 -770.228C163.431 -706.847 102.386 -624.871 64.1663 -517.223C25.9468 -409.574 -22.9014 -296.84 13.4978 -194.345C49.897 -91.8501 152.768 -15.4995 252.796 51.0215C352.824 117.543 463.971 134.833 581.752 134.833C699.533 134.833 802.574 107.392 902.636 40.8574C1002.7 -25.6772 1078.65 -102.239 1115.05 -204.748C1151.44 -307.257 1204.69 -429.541 1166.47 -537.182C1128.25 -644.824 1005.04 -713.113 909.754 -776.474C814.464 -839.834 705.464 -885.167 581.779 -885.167Z" stroke="#7DDE8B"/>
                        </svg>
                        <div className="cta-vijay-quote__text">
                            <span className="cta-vijay-quote__text-quotes">“ </span>
                            Whether you’re new to Edyn or a long-term client, you’ll get compassionate and invaluable support from our expert team. Our family care advisors are here to help you navigate your care options to ensure you get the right care, at the right time.
                            <span className="cta-vijay-quote__text-quotes">”</span>
                        </div>
                        <div className="cta-vijay-quote__profile">
                            <StaticImage className="cta-vijay-quote__profile-img" src="../../static/assets/marketing-site/images/team/tilda.png" placeholder="blurred" objectFit="contain"/>
                            <div className="cta-vijay-quote__profile-text">
                                <div>Tilda Mew</div>
                                <div>Sales Manager</div>
                            </div>
                        </div>
                    </section>

                    <section className="cta-next-steps-section">
                        <h2 className="cta-next-steps-section__title">Next steps</h2>
						<div className="approach-steps-v2 mobile">
							<div className='approach-steps__circle-0'> </div>
							<div className='approach-steps__line approach-steps__line-0'> </div>
							<div className='approach-steps__circle approach-steps__circle-1'>1</div>
							<div className='approach-steps__line approach-steps__line-1'> </div>
							<div className='approach-steps__circle approach-steps__circle-2'>2</div>
							<div className='approach-steps__line approach-steps__line-2'> </div>
							<div className='approach-steps__circle approach-steps__circle-3'>3</div>
							<div className='approach-steps__section approach-steps__section-initial'>
								<StaticImage
									className='approach-steps__section-bottom-image'
									placeholder="blurred"
									src="../../static/assets/marketing-site/images/cta-next-steps-1.png"
								/>
							</div>
							<div className='approach-steps__subtitle approach-steps__subtitle-getting-to-know-you'>
								<h3>Care consultation</h3>
							</div>
							<div className='approach-steps__section approach-steps__section-getting-to-know-you'>
								<p className="approach-steps__paragraph">As well as advising on whether edyn is suitable, during this call we can offer expert advice on navigating the care sector.</p>
								<button className="approach-steps__button">
									Book your free care consultation
								</button>
								<StaticImage
									className='approach-steps__section-bottom-image'
									placeholder="blurred"
									src="../../static/assets/marketing-site/images/cta-next-steps-2.png"
								/>
							</div>
							<div className='approach-steps__subtitle approach-steps__subtitle-matching-your-care-team'>
								<h3>Care assessment</h3>
							</div>
							<div className='approach-steps__section approach-steps__section-matching-your-care-team'>
								<br />
								<p className="approach-steps__paragraph">A no-obligation care assessment from one of our care managers. They will learn what you’re hoping to achieve from your care, ensure your property is safe and will share a digital care plan of your goals.</p>
								<div className="approach-steps__checklist">
									<div className="approach-steps__checklist-item">
										<GreenCheckMark className="approach-steps__checklist-tick-icon" />
										<p className="secondary">Trained by us</p>
									</div>
									<div className="approach-steps__checklist-item">
										<GreenCheckMark className="approach-steps__checklist-tick-icon" />
										<p className="secondary">Insured</p>
									</div>
									<div className="approach-steps__checklist-item">
										<GreenCheckMark className="approach-steps__checklist-tick-icon" />
										<p className="secondary">DBS Check</p>
									</div>
								</div>
								<StaticImage
									className='approach-steps__section-bottom-image'
									placeholder="blurred"
									src="../../static/assets/marketing-site/images/cta-next-steps-3.png"
								/>
							</div>
							<div className='approach-steps__subtitle approach-steps__subtitle-carer-introduction'>
								<h3>Carer matching:</h3>
							</div>
							<div className='approach-steps__section approach-steps__section-carer-introduction'>
								<p className="approach-steps__paragraph">We match your relative to professional carers on needs and personality, before sending over information about them through our online portal.</p>
							</div>
						</div>
                        <div className="approach-steps-container">
                            <div className="approach-step before-first-step">
                                <div className="approach-step-left"> </div>
                                <div className="approach-step-center">
                                    <span></span>
                                </div>
                                <div className="approach-step-right"> </div>
                            </div>
                            <div className="approach-step">
                                <div className="approach-step-left">
                                    <StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/cta-next-steps-1.png" />
                                </div>
                                <div className="approach-step-center">
                                    <span>1</span>
                                </div>
                                <div className="approach-step-right">
                                    <h3>Care consultation</h3>
                                    <p className="primary">As well as advising on whether edyn is suitable, during this call we can offer expert advice on navigating the care sector.</p>
                                    <Link to='/cta/'>
                                        <button className="btn-primary">
                                            Book your free care consultation
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="approach-step">
                                <div className="approach-step-left">
                                    <StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/cta-next-steps-2.png" />
                                </div>
                                <div className="approach-step-center">
                                    <span>2</span>
                                </div>
                                <div className="approach-step-right">
                                    <h3>Care assessment</h3>
                                    <p className="primary">A no-obligation care assessment from one of our care managers. They will learn what you’re hoping to achieve from your care, ensure your property is safe and will share a digital care plan of your goals.</p>
                                    <div className="approach-steps-checklist">
                                        <div className="ap-checklist">
                                            <GreenCheckMark className="icon-default" />
                                            <p className="secondary">Trained By Us</p>
                                        </div>
                                        <div className="ap-checklist">
                                            <GreenCheckMark className="icon-default" />
                                            <p className="secondary">Insured</p>
                                        </div>
                                        <div className="ap-checklist">
                                            <GreenCheckMark className="icon-default" />
                                            <p className="secondary">DBS Check</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="approach-step">
                                <div className="approach-step-left">
                                    <StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/cta-next-steps-3.png" />
                                </div>
                                <div className="approach-step-center last">
                                    <span>3</span>
                                </div>
                                <div style={{paddingBottom: 70}} className="approach-step-right">
                                    <h3>Carer matching:</h3>
                                    <p className="primary">We match your relative to professional carers on needs and personality, before sending over information about them through our online portal.</p>
                                </div>
                            </div>
                        </div>
                   </section>
                   <section className="cta-call-us-section">
                       <div className="cta-call-us-section__container">
                           <PhoneIconCard className="cta-call-us-section__phone-icon"/>
                           <div className="cta-call-us-section__text-and-phone">
                               <div className="cta-call-us-section__text">If you’d rather, you can just call us now:</div>
                               <a href="tel:02039709900" className="cta-call-us-section__phone">020 3970 9900</a>
                           </div>
                       </div>
                   </section>
                    <Trustpilot className="cta-trustpilot"/>
                    {/* Next steps */}
				</main>
                <Footer includeCTA={false} />
			</div>
		</>
	);
}
